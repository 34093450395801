import React, { useState, forwardRef, useImperativeHandle  } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import {host} from "../utils/Constants";

const CreateClientDialog = forwardRef((props, ref) => {
    const [addEmploye , setAddEmploye] = useState(false);
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    const action = () => {
        if (props.edit) {
            editClient(props.id)
        } else {
            createClients()
        }
    }

    const editClient = (id) => {
        let url = host + "clients/" + id + "/?"

        let ids = ["name", "email", "dni", "phone", "instagram", "facebook"]
        for (let i in ids) {
            let value = document.getElementById(ids[i]).value
            if (value !== null && value !== "") {
                url += ids[i] + "=" + value + "&"
            }
        }

        if (url !== host + "clients/" + id + "/?") {
            fetch(url, {method: 'PUT'})
                .then(function(response) {
                    window.location.reload();
                    setAddEmploye(false)
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    }

    const createClients = () => {
        let url = host + "clients/?"

        url += "name=" + document.getElementById("name").value + "&"
        url += "dni=" + document.getElementById("dni").value + "&"
        url += "phone=" + document.getElementById("phone").value + "&"
        url += "email=" + document.getElementById("email").value + "&"
        url += "instagram=" + document.getElementById("instagram").value + "&"
        url += "facebook=" + document.getElementById("facebook").value


        fetch(url, {method: 'POST'})
            .then(function(response) {
                window.location.reload();
                setAddEmploye(false)
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    const deleteClient = (id) => {
        if (window.confirm("Quiere eliminar este cliente ?")) {
            let url = host + "clients/" + id + "/"
            fetch(url, {method: 'DELETE'})
                .then(function(response) {
                    window.location.reload();
                    setAddEmploye(false)
                })
                .catch(function(error) {
                    console.log(error);
                });

        }
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Nombre</label>
                                <input type="text" className="form-control" id="name" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">DNI</label>
                                <input type="text" className="form-control" id="dni" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Telefono</label>
                                <input type="text" className="form-control" id="phone" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                                <input type="text" className="form-control" id="email" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Instagram</label>
                                <input type="text" className="form-control" id="instagram" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Facebook</label>
                                <input type="text" className="form-control" id="facebook" placeholder="" />
                            </div>


                        </div>
                        <div>
                            <button onClick={() => { action() }} className="btn btn-primary me-1">Aceptar</button>
                            <Link to={"#"} onClick={()=> { setAddEmploye(false)}} className="btn btn-danger light ms-1">Cancelar</Link>
                            {
                                props.edit === true ? (<Link to={"#"} onClick={()=> { deleteClient(props.id)}} className="btn btn-danger light ms-1">Eliminar</Link>) : (<></>)
                            }
                        </div>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default CreateClientDialog;