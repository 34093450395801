import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {useNavigate} from "react-router-dom";
import Product from "./items/Product";
import CreateProductDialog from "./create/CreateProductDialog";
import TableItems from "./objects/TableItems";
import FindTable from "./objects/FindTable";
import {host} from "./utils/Constants";

const Home = () => {
	const navigate = useNavigate();

	const dialog = useRef();

	const [items, setItems] = useState([]);

	useEffect(() => {
		fetch(host + "product/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				setItems(formatData(data))
			})
			.catch(function(error) {
				console.log(error);
			});

	}, []);

	const formatData = (data) => {
		let items = []
		for(let i in data.products){
			let jsonData = Object.values(data.products[i])

			let stock = 0
			for (let i = 0; i < jsonData[1].length; i++) {
				stock += jsonData[1][i].stock
			}

			items.push({
				"_id": jsonData[0]._id,
				"name": jsonData[0].name,
				"price": jsonData[0].price,
				"marca": jsonData[0].marca,
				"stock": stock,
				"variants": jsonData[1].length
			})
		}
		return items
	}

	const formatFindData = (data) => {
		let items = []
		for(let i in data.products){
			let jsonData = Object.values(data.products[i])

			let stock = 0
			for (let i = 0; i < jsonData[1].length; i++) {
				stock += jsonData[1][i].stock
			}

			items.push({
				"_id": jsonData[0]._id,
				"name": jsonData[0].name,
				"price": jsonData[0].price,
				"marca": jsonData[0].marca,
				"stock": stock,
				"variants": jsonData[1].length
			})
		}
		return items
	}

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<FindTable
						tableColumns={["Nombre", "Marca", "Precio", "Stock", "Variantes", "Accion"]}
						data={ items }
						dataItem={ (data) => { return <Product data={data}/> } }
						url={host + "product/"}
					/>


					<TableItems
						title="Productos"
						creator={dialog}
						creatorDialog="Crear producto"
						tableColumns={ [
							{
								key: "name",
								value: "Nombre"
							},
							{
								key: "price",
								value: "Precio"
							},
							{
								key: "marca",
								value: "Marca"
							},
							{
								key: "stock",
								value: "Stock"
							},
							{
								key: null,
								value: "Variantes"
							},
							{
								key: null,
								value: "Accion"
							}
						]}
						data={items}
						button={() => { return (
							<a type="button" className="btn btn-primary light btn-sm me-1" href={host + "export/stock"}>
								Exportar
							</a>
						)
						}}
						dataItem={ (data) => { return <Product data={data}/> } }
					/>
				</div>
			</div>

			<CreateProductDialog ref={dialog} edit={false} Title="Crear producto"/>
		</>
	);

}
export default Home;