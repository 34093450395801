import React, { useState, forwardRef, useImperativeHandle  } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import {host} from "../utils/Constants";

const CreateClientDialog = forwardRef((props, ref) => {
    const [addEmploye , setAddEmploye] = useState(false);
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    const createItem = () => {
        let url = host + "lists/" + props.list + "/?names=name&"

        url += "values=" + document.getElementById("name").value.replace(",", "") + "&"

        fetchWithAuth(url, {method: 'POST'})
            .then(function(response) {
                window.location.reload();
                setAddEmploye(false)
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Nombre</label>
                                <input type="text" className="form-control" id="name" placeholder="" />
                            </div>
                        </div>
                        <div>
                            <button onClick={() => { createItem() }} className="btn btn-primary me-1">Aceptar</button>
                        </div>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default CreateClientDialog;