import React from 'react';
//import { useNavigate } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";
import {
    formatError,
    login
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';


export function Logout(navigate) {
    const auth = getAuth();
    signOut(auth).then(() => {
        localStorage.removeItem('userToken');
        navigate('/login');
    }).catch((error) => {

    });
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate) {
    return  (dispatch) => {
         login(email, password)
            .then((userCredential) => {
                console.log("Login successful:", userCredential);

                const user = userCredential.user
                user.getIdToken().then(idToken => {
                    localStorage.setItem("userToken", idToken);
                    dispatch(loginConfirmedAction());
                    navigate('/dashboard');
                })
            })
            .catch((error) => {
                const errorMessage = formatError(error);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction() {
    return {
        type: LOGIN_FAILED_ACTION,
    };
}

export function loginConfirmedAction() {
    return {
        type: LOGIN_CONFIRMED_ACTION,
    };
}

export function confirmedSignupAction() {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export const navtoggle = () => {
    return {        
      type: 'NAVTOGGLE',
    };
};