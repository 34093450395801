import React, {useContext, useEffect, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import Sale from "./items/Sale";
import TableItems from "./objects/TableItems";
import {host} from "./utils/Constants";
import {CSVLink} from "react-csv";
import {getReport} from "./utils/Utils";
import SaleProductItem from "./items/SaleProductItem";

const Home = () => {

	const [items, setItems] = useState([]);

	useEffect(() => {

		fetch(host + "sales/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let items = []
				for(let i in data.sales){
					let jsonData = data.sales[i]

					let products = data.sales[i].sales
					let variantsList = []
					for (let index in products) {
						variantsList.push(products[index].stock + " - " + products[index].name)
					}

					items.push({
						"_id": jsonData._id,
						"client_id": jsonData.client_id,
						"name": jsonData.client_name,
						"date": jsonData.date,
						"payment_method":jsonData.payment_method,
						"sales": jsonData.sales,
						"total": jsonData.total,
						"products": <SaleProductItem data={variantsList}/>
					})

				}
				setItems(items)
			})
			.catch(function(error) {
				console.log(error);
			});

	}, []);



	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<TableItems
						title="Ventas"
						tableColumns={
							[
								{
									key: "name",
									value: "Nombre"
								},
								{
									key: "payment_method",
									value: "Metodo de Pago"
								},
								{
									key: null,
									value: "Productos"
								},
								{
									key: "date",
									value: "Fecha"
								},
								{
									key: "total",
									value: "Total"
								},
							]
						}
						data={items}
						button={() => { return (
								<a type="button" className="btn btn-primary light btn-sm me-1" href={host + "export/sales"}>
									Exportar
								</a>
							)
						}}
						dataItem={ (data) => { return <Sale data={data}/> } }
					/>
				</div>
			</div>
		</>
	);

}
export default Home;