import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {Card, Col, Nav, Tab} from "react-bootstrap";
import CountUp from "react-countup";
import {getList} from "./utils/Utils";
import {host} from "./utils/Constants";
import {SVGICON} from "../../constant/theme";
import DepositlineChart from "./graphics/DepositlineChart";
import ReactApexChart from "react-apexcharts";
import {sum} from "react-table/src/aggregations";
import {Link} from "react-scroll";
import CreateClientDialog from "./create/CreateClientDialog";
import CreateMetaDialog from "./create/CreateMetaDialog";
import StatusByType from "./status/StatusByType";

const Home = () => {

	const dialog = useRef();

	const [data, setData] = useState([]);

	useEffect(() => {
		fetch(host + "information/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let jsonData = Object.values(data)

				let dic = [
					{number: jsonData[1], countText: 'success', title:'Ventas'},
					{number: jsonData[2], countText: 'warning', title:'Usuarios'},
					{number: jsonData[0], countText: 'primary', title:'Productos'},
					{number: jsonData[3], countText: 'purple', title:'Variantes'},
				]

				setData(dic)
			})
			.catch(function(error) {
				console.log(error);
			});

	}, []);



	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
			<div className="container-fluid">
				<Col xl="12">
					<Tab.Container defaultActiveKey="zone">

						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row task">
										{data.map((item, index)=>(
											<div className="col-xl-2 col-sm-4 col-6" key={index}>
												<div className="task-summary">
													<div className="d-flex align-items-baseline">
														<CountUp className={`mb-0 fs-28 fw-bold me-2 text-${item.countText}`}  end={item.number} duration={'5'} />
														<h6 className='mb-0'>{item.title}</h6>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>

						<Card  name="badges-light" className="dz-card">
							<Card.Header className="d-flex justify-content-between flex-wrap">
								<div>
									<Card.Title style={{float: "left"}}>Metas</Card.Title>
									<Link style={{ position: "absolute", top: "10px", right: "10px"}} to={"#"} className="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
										  onClick={() => dialog.current.showModal()}
									>+ Agregar Meta del Mes</Link> {" "}
								</div>
							</Card.Header>
						</Card>

						<StatusByType data={"Prenda"}/>
						<StatusByType data={"Accesorio"}/>
					</Tab.Container>
				</Col>
			</div>
			<CreateMetaDialog ref={dialog} Title="Crear Meta" edit={false}/>
		</>
	);

}
export default Home;