import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import Client from "./items/Client";
import {useNavigate} from "react-router-dom";
import CreateClientDialog from "./create/CreateClientDialog";
import TableItems from "./objects/TableItems";
import {host} from "./utils/Constants";

const Clients = () => {
	const navigate = useNavigate();

	const dialog = useRef();

	const [items, setItems] = useState([]);

	useEffect(() => {

		fetch(host + "clients/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let items = []
				for(let i in data.clients){
					let jsonData = Object.values(data.clients[i])

					items.push({
						"_id": jsonData[0],
						"dni": jsonData[1],
						"email": jsonData[2],
						"facebook": jsonData[3],
						"instagram": jsonData[4],
						"name": jsonData[5],
						"phone": jsonData[6],
					})
				}
				setItems(items)
			})
			.catch(function(error) {
				console.log(error);
			});

	}, []);

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<TableItems
						title="Clientes"
						creator={dialog}
						creatorDialog="Crear cliente"
						tableColumns={
							[
								{
									key: "dni",
									value: "DNI"
								},
								{
									key: "name",
									value: "Nombre"
								},
								{
									key: "email",
									value: "Email"
								},
								{
									key: "phone",
									value: "Telefono"
								},
								{
									key: null,
									value: "Redes"
								},
								{
									key: null,
									value: "Detalles"
								},
							]
						}
						button={() => { return (
							<a type="button" className="btn btn-primary light btn-sm me-1" href={host + "export/clients"}>
								Exportar
							</a>
						)
						}}
						data={items}
						dataItem={ (data) => { return <Client data={data}/> } }
					/>
				</div>
			</div>

			<CreateClientDialog ref={dialog} Title="Crear cliente" edit={false}/>
		</>
	);

}
export default Clients;