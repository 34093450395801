export const host = "https://api.vienasma.com/api/v1/"

function ensureTrailingSlash(url) {
    if (!url.endsWith('/')) {
        url += '/';
    }
    return url;
}

export const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem("userToken")
    url = ensureTrailingSlash(url)

    const headers = {
        "Content-Type": "application/json",
        ...(token ? { "Authorization": `Bearer ${token}` } : {}),
        ...options.headers,
    };

    const fetchOptions = {
        ...options,
        headers,
    };

    return fetch(url, fetchOptions);
};