import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {useNavigate} from "react-router-dom";
import Product from "./items/Product";
import CreateProductDialog from "./create/CreateProductDialog";
import TableItems from "./objects/TableItems";
import FindTable from "./objects/FindTable";
import {host} from "./utils/Constants";

const Home = () => {

	const dialog = useRef();

	const formatData = (data) => {
		let items = []
		for(let i in data.products){
			let jsonData = data.products[i]

			console.log(jsonData)

			items.push({
				"_id": jsonData._id,
				"name": jsonData.name,
				"price": jsonData.price,
				"marca": jsonData.marca,
				"stock": jsonData.stock,
				"variants": jsonData.variants
			})
		}
		return items
	}

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<FindTable
						tableColumns={["Nombre", "Marca", "Precio", "Stock", "Variantes", "Accion"]}
						endpoint="products"
						formatData={ (data) => { return formatData(data) }}
						dataItem={ (data) => { return <Product data={data}/> } }
					/>

					<TableItems
						title="Productos"
						creator={dialog}
						creatorDialog="Crear producto"
						endpoint="products"
						tableColumns={ [
							{
								key: "name",
								value: "Nombre"
							},
							{
								key: "price",
								value: "Precio"
							},
							{
								key: "marca",
								value: "Marca"
							},
							{
								key: "stock",
								value: "Stock"
							},
							{
								key: null,
								value: "Variantes"
							},
							{
								key: null,
								value: "Accion"
							}
						]}
						button={() => { return (
							<a type="button" className="btn btn-primary light btn-sm me-1" href={host + "export/stock"}>
								Exportar
							</a>
						)
						}}
						dataItem={ (data) => { return <Product data={data}/> } }
						formatData={ (data) => { return formatData(data) }}
					/>
				</div>
			</div>

			<CreateProductDialog ref={dialog} edit={false} Title="Crear producto"/>
		</>
	);

}
export default Home;