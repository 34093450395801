import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import CreateClientDialog from "../create/CreateClientDialog";
import {host} from "../utils/Constants";

const StockLog = (data) => {
	const navigate = useNavigate();

	const [name, setName] = useState([]);

	useEffect(() => {

		fetch(host + "product/variant/" + data.data.variant_id + "/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				setName(data.name)
			})
			.catch(function(error) {
				console.log(error);
			});
	}, []);

	return (
		<>
			<tr>
				<td><span>{ name }</span></td>
				<td><span>{ data.data.creation_date }</span></td>
				<td><span>{ data.data.stock }</span></td>
			</tr>
		</>
	);

}
export default StockLog;