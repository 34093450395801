import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import Select from "react-select";
import {getList, makeSelectList} from "../utils/Utils";
import {host} from "../utils/Constants";

const CreateMetaDialog =forwardRef((props, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [addEmploye , setAddEmploye] = useState(false);

    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    const action = () => {
        let valor_prendas_challenge = document.getElementById("valor_prendas_challenge").value.replace(",", "")
        let valor_accesories_challenge = document.getElementById("valor_accesories_challenge").value.replace(",", "")
        if (valor_accesories_challenge !== null && valor_accesories_challenge !== "") {
            let url = host + "challenges/Accesorio/" + valor_accesories_challenge + "/"
            fetchWithAuth(url, {method: 'POST'})
                .then(function(response) {
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
        if (valor_prendas_challenge !== null && valor_prendas_challenge !== "") {
            let url = host + "challenges/Prenda/" + valor_prendas_challenge + "/"
            fetchWithAuth(url, {method: 'POST'})
                .then(function(response) {
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
        setAddEmploye(false)
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Prendas</label>
                                <input type="number" className="form-control" id="valor_prendas_challenge" placeholder="100"/>
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Accesorios</label>
                                <input type="number" className="form-control" id="valor_accesories_challenge" placeholder="100"/>
                            </div>

                        </div>
                        <div>
                            <button onClick={() => {
                                action()
                            }} className="btn btn-primary me-1">Aceptar
                            </button>
                            <Link to={"#"} onClick={() => {
                                setAddEmploye(false)
                            }} className="btn btn-danger light ms-1">Cancelar</Link>
                        </div>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default CreateMetaDialog;