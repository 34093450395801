import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';

const CreateVariantDialog =forwardRef((props, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [addEmploye , setAddEmploye] = useState(false);
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    useEffect(() => {
        console.log(props.data)
    }, []);

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="c-details">
                                <ul>
                                    {props.data.map((item, index)=>(
                                        <li key={index}>
                                            <span>{item.name}</span>
                                            <p>{item.subtitle}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                        <div>
                            <button onClick={() => { setAddEmploye(false) }} className="btn btn-primary me-1">Aceptar</button>
                        </div>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default CreateVariantDialog;