import React, {useContext, useEffect, useRef, useReducer, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import MainPagetitle from "../../../layouts/MainPagetitle";
import {host} from "../utils/Constants";
import TableItems from "../objects/TableItems";
import Variant from "../items/Variant";
import Sale from "../items/Sale";

const ClientDescription = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [items, setItems] = useState([]);
    const [data, setData] = useState({});

    let id = searchParams.get("id")

    useEffect(() => {

        fetch(host + "sales/" + id + "/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let items = []
                for(let i in data.sales){
                    let jsonData = data.sales[i]

                    items.push({
                        "_id": jsonData._id,
                        "client_id": jsonData.client_id,
                        "date": jsonData.date,
                        "payment_method":jsonData.payment_method,
                        "sales": jsonData.sales,
                        "total": jsonData.total
                    })
                }

                setItems(items)
            })
            .catch(function(error) {
                console.log(error);
            });

        fetch(host + "clients/" + id + "/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let jsonData = Object.values(data)

                let item = {
                    "_id": jsonData[0],
                    "dni": jsonData[1],
                    "email": jsonData[2],
                    "facebook": jsonData[3],
                    "instagram": jsonData[4],
                    "name": jsonData[5],
                    "phone": jsonData[6],
                }

                setData(item)
            })

    }, []);
    return (
        <>
            <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
            <div className='container-fluid'>
                <div className='row'>

                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count}`}>{data.name !== "null" ? data.name : "Sin datos"}</h6>
                                        <h6>Nombre</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count`}>{data.dni !== "null" ? data.dni : "Sin datos"}</h6>
                                        <h6>DNI</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count`}>{data.email !== "null" ? data.email : "Sin datos"}</h6>
                                        <h6>Email</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count`}>{data.phone !== "null" ? data.phone : "Sin datos"}</h6>
                                        <h6>Telefono</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count`}>I.@{data.instagram !== "null" ? data.instagram : ""} F.@{data.facebook !== "null" ? data.facebook : ""}</h6>
                                        <h6>Marca</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <TableItems
                        title="Ventas"
                        tableColumns={
                            [
                                {
                                    key: "name",
                                    value: "Nombre"
                                },
                                {
                                    key: "payment_method",
                                    value: "Metodo de Pago"
                                },
                                {
                                    key: null,
                                    value: "Productos"
                                },
                                {
                                    key: "date",
                                    value: "Fecha"
                                },
                                {
                                    key: "total",
                                    value: "Total"
                                },
                            ]
                        }
                        data={items}
                        button={() => { return (
                            <a type="button" className="btn btn-primary light btn-sm me-1" href={host + "export/sales/" + id + "/"}>
                                Exportar
                            </a>
                        )
                        }}
                        dataItem={ (data) => { return <Sale data={data}/> } }
                    />

                </div>
            </div>
        </>
    );

}

export default ClientDescription;